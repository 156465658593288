export const countryMap: Record<string, string> = {
  AR: "Argentina",
  BR: "Brazil",
  CL: "Chile",
  PE: "Peru",
  MX: "Mexico",
  ES: "Spain",
  EG: "Egypt",
  US: "United States",
  TW: "Taiwan",
  HK: "Hong Kong",
  SG: "Singapore",
  DE: "Germany",
  CO: "Colombia",
  EC: "Ecuador",
  CR: "Costa Rica",
  UY: "Uruguay",
  DO: "Dominican Republic",
  GB: "Great Britain",
  PT: "Portugal",
  LA: "Laos",
  IT: "Italy",
  FR: "France",
  BE: "Belgium",
  NL: "Netherlands",
  CH: "Switzerland",
  IE: "Ireland",
  CA: "Canada",
  ZZ: "Unknown",
};
